<template>
    <div ref="modalInputEmail" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalInputEmail" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0 py-1 mx-3">
                    <button type="button" class="close pt-3" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-2">
                        <label>{{ $t('message.InputQuickSearch.title.email') }}:</label>
                        <br>
                        <i class="text-danger">{{ $t('message.common.noteModalInputEmail') }}</i>
                        <InputText v-model="email"/>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-success mr-2" @click="confirm()" :disabled="!this.email ? true : false">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="hide()">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import InputText from './forms/InputText.vue';
export default {
    name: "ModalInputEmail",
    components: {
        InputText
    },
    data(){
        return {
            email: null,
        }
    },
    props: [''],
    methods: {
        show() {
            this.email = null;
            window.jQuery(this.$refs.modalInputEmail).modal('show');
        },
        async confirm(){
            if(!this.email){
                return;
            }
            this.$emit('confirm', this.email);
            this.hide();
        },
        async hide() {
            window.jQuery(this.$refs.modalInputEmail).modal('hide');
        },
    }
}
</script>

