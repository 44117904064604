<template>
    <div v-if="isDataLoaded">
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.code') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <!-- <InputText v-if="this.param != null" v-model="entry.code" :errors="errors.code" name="productId" placeholder="Mã hàng tự động" /> -->
                                    <InputText v-if="!this.param" v-model="entry.code" :errors="errors.code" name="productId" :placeholder="$t('message.ModalProductFormTabMain.placeholder.code')" />
                                    <InputText v-else-if="this.param == this.$constant.status_isUpdate" v-model="entry.code" :errors="errors.code" name="productId" :readonly="true"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <b>
                                    {{ $t('message.ModalProductFormTabMain.name') }}
                                    <label class="text-danger px-0" v-if="this.param != this.$constant.status_isUpdate"><strong>*</strong></label>
                                </b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <!-- <InputText v-if="this.param !=null" v-model="entry.name" :errors="errors.name" placeholder="Nhập tên hàng hoá"/> -->
                                    <InputText v-if="this.param ==null" v-model="entry.name" :errors="errors.name" :placeholder="$t('message.ModalProductFormTabMain.placeholder.name')"/>
                                    <InputText v-else-if="this.param == this.$constant.status_isUpdate" v-model="entry.name" :errors="errors.name" :readonly="true"  />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.ProductIndex.price')}}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputNumberFormat v-model="entry.basePrice"
                                     :errors="errors.basePrice" :readonly="true"/>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="form-group" style="display: none">
                            <label>Giá vốn</label>
                            <InputNumber v-model="entry.capitalPrice"
                                     :errors="errors.capitalPrice" />
                        </div> -->
                        <!-- <InputTextArea v-model="entry.position" :errors="errors.position" label="Vị trí"/> -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.category') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">                                    
                                    <q-tree-select-input name="categoryId" :options="categoryTree" :single="false" v-model="entry.categoryId"/>
                                    <span class="add-q-btn" @click="showModalCategoryAdd(1)">
                                        <i class="fa fa-plus px-1" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- Loại hàng hóa -->
                        <div class="row">
                            <div class="col-3">
                                <b>
                                    {{ $t('message.ModalProductFormTabMain.type') }}
                                    <label class="text-danger" v-if="this.param != this.$constant.status_isUpdate"><strong>*</strong></label>
                                </b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">                                    
                                    <q-select v-model="entry.type" :defaultOptions="this.$constant.productTypes" :multiple="false" 
                                    :readonly="this.type =='EDIT' ? true : false" 
                                    :placeholder="$t('message.ModalProductFormTabMain.placeholder.type')" 
                                    @update:modelValue="typeUpdated()"/>
                                    <ErrorLabel :errors="errors.type"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <b>
                                    {{ $t('message.ModalProductFormTabMain.hs_code') }}
                                    <label class="text-danger px-0" v-if="this.param != this.$constant.status_isUpdate"></label>
                                </b>
                            </div>
                            <div class="col-9">                                
                                <div class="form-group"> 
                                    <!-- Input text -->
                                    <InputText v-model="entry.hsCode" :max="8" :placeholder="$t('message.ModalProductFormTabMain.placeholder.hs_code')" :cusNumber="true"/>                                    
                                </div>
                            </div>
                        </div>
                        <!-- Thuế suất -->
                        <div class="row">
                            <div class="col-3">
                                <b>
                                    {{ $t('message.ModalProductFormTabMain.tax_rate') }}
                                    <label class="text-danger pl-1" v-if="this.param != this.$constant.status_isUpdate">*</label>
                                </b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <SelectSearch :placeholder="$t('message.ModalProductFormTabMain.placeholder.tax_rate')" :modelValue="entry.taxRate" :defaultOption="taxRate" :locationStyle="true"
                                    @update:modelValue="updateValue"
                                    ></SelectSearch>
                                   
                                </div>
                            </div>
                        </div>
                        <!-- Số ngày sử dụng -->
                        <div class="row">
                            <div class="col-3 pr-0">
                                <b>
                                    {{ $t('message.ModalProductFormTabMain.lifeTime') }}
                                    <label class="text-danger pl-1" v-if="this.param != this.$constant.status_isUpdate">*</label>
                                </b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">                                    
                                    <InputText v-model="entry.lifeTime" :errors="errors.lifeTime" name="productId" 
                                    :placeholder="$t('message.ModalProductFormTabMain.placeholder.lifeTime')" :cusNumber="true"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.image') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputFileManager max="3" v-model="entry.image" :multiple="true"/>
                                    <ErrorLabel :errors="errors.image"/>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="this.entry.type == this.$constant.typeProduct">
                            <div class="col-12" v-if="this.statusOption.consignment">                                
                                <label class="container-checkbox d-flex m-0" 
                                :class="this.type =='EDIT' ? 'cursor-ban' : '' "
                                >
                                    {{ $t('message.ModalProductFormTabMain.batch') }}
                                    <div class="container-circle tooltip-left-center">
                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                        <div class="content-circle font-weight-normal">
                                            {{ $t('message.ModalProductFormTabMain.batch') }}
                                        </div>
                                    </div>                                                                     
                                    <input type="checkbox" class="chkType" v-model="entry.isConsignment" 
                                    :checked="entry.isConsignment ? true : false" 
                                    :disabled="this.type =='EDIT' ? true : false"                                    
                                    >
                                    <!-- :disabled="(entry.consignments && entry.consignments?.length > 0) || entry.haveTransaction || entry.isConsignment ? true : false" 
                                    :class="(entry.consignments && entry.consignments?.length > 0) || entry.haveTransaction || (entry.isConsignment)  ? 'cursor-ban' : ''" -->
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="this.entry.type == this.$constant.typeProduct">
                            <div class="col-12">
                                <label class="container-checkbox d-flex m-0">{{ $t('message.ModalProductFormTabMain.medicine') }}
                                    <div class="container-circle tooltip-left-center">
                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                        <div class="content-circle font-weight-normal">
                                            {{ $t('message.ModalProductFormTabMain.medicine') }}
                                        </div>
                                    </div>
                                    <input type="checkbox" class="chkType" v-model="entry.isMedicine" :checked="entry.isMedicine ? true : false">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="entry.isMedicine">
                            <div class="col-3">
                                <label class="container-checkbox d-flex m-0 pl-0">
                                    <b>
                                        {{ $t('message.ModalProductFormTabMain.medicine_name') }}
                                        <label class="text-danger px-0"><strong>*</strong></label>
                                    </b>
                                    <div class="container-circle tooltip-left-center">
                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                        <div class="content-circle font-weight-normal">
                                            {{ $t('message.ModalProductFormTabMain.medicine_name') }}
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <MedicineSearch @selectMedicine="selectMedicine" :defaultValue="entry.medicineName" :placeholder="$t('message.ModalProductFormTabMain.placeholder.medicine_name')"></MedicineSearch>
                                    <!-- <InputText placeholder="Nhập tên thuốc"/> -->
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="entry.isMedicine">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.medicine_code') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputText v-model="entry.medicineCode" :placeholder="$t('message.ModalProductFormTabMain.placeholder.medicine_code')" :readonly="true"/>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="entry.isMedicine">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.medicine_short_name') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputText v-model="entry.shortName" :placeholder="$t('message.ModalProductFormTabMain.placeholder.medicine_short_name')"/>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="entry.isMedicine">
                            <div class="col-3">
                                <b>
                                    {{ $t('message.ModalProductFormTabMain.medicine_road_for_use') }}
                                    <label class="text-danger"><strong>*</strong></label>
                                </b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">                                    
                                    <q-select :default-options="routeOfUse" v-model="entry.routeOfUseIds" :isEditRouteOfUse="true" :multiple="true" :placeholder="$t('message.ModalProductFormTabMain.placeholder.medicine_road_for_use')"/>
                                    <span class="add-q-btn" @click="showModalCreateRouteOfUse()">
                                        <i class="fa fa-plus px-1"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mt-2">
                            <div class="col-12">
                                <label class="container-checkbox d-flex m-0">Bán trực tiếp
                                    <div class="container-circle tooltip-left-center">
                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                        <div class="content-circle font-weight-normal">
                                            Sản phẩm sẽ xuất hiện trên màn hình bán hàng
                                        </div>
                                    </div>
                                    <input type="checkbox" class="chkType">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Input hoạt chất -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.ingredient') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputText v-model="entry.activeIngredient" :placeholder="$t('message.ModalProductFormTabMain.placeholder.ingredient')"/>
                                    <ErrorLabel :errors="errors.activeIngredient"/>
                                </div>
                            </div>
                        </div>
                        <!-- Input Hãng sản xuất -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.manufacturer') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputText v-model="entry.manufacturer" :placeholder="$t('message.ModalProductFormTabMain.placeholder.manufacturer')"/>
                                    <ErrorLabel :errors="errors.manufacturer"/>
                                </div>
                            </div>
                        </div>
                        <!-- Input nước sản xuất -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.country') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputText v-model="entry.country" :placeholder="$t('message.ModalProductFormTabMain.placeholder.country')"/>
                                    <ErrorLabel :errors="errors.country"/>
                                </div>
                            </div>
                        </div>
                        <!-- Input quy cách đóng gói -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.packing') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputText v-model="entry.unit" :placeholder="$t('message.ModalProductFormTabMain.placeholder.packing')"/>
                                    <ErrorLabel :errors="errors.unit"/>
                                </div>
                            </div>
                        </div>
                        <!-- Input quy cách -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.description') }}</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputTextArea v-model="entry.description" :placeholder="$t('message.ModalProductFormTabMain.placeholder.description')"/>
                                    <ErrorLabel :errors="errors.description"/>
                                </div>
                            </div>
                        </div>
                        <!-- Input Trọng lượng -->
                        <!-- <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.InvoiceIndex.tabs.delivery.weight')}} (gr)</b>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <InputNumber v-model="entry.weight" placeholder="Nhập khối lượng hàng hoá" :formatNumber="true"/>
                                    <ErrorLabel :errors="errors.weight"/>
                                </div>
                            </div>
                        </div> -->
                        <!-- Input Kinh doanh tại kho -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{ $t('message.ModalProductFormTabMain.branch') }}</b>
                            </div>
                            <div class="col-9">                                
                                <div class="form-group">
                                    <q-select name="branchIDs" :default-options="branches" :multiple="true" v-model="entry.branchIDs" :selectAll="true" :placeholder="$t('message.ModalProductFormTabMain.placeholder.branch')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <hr>
        <div class="row border m-0 p-0 rounded" v-if="entry.isMedicine">
            <div class="col-lg-12 p-3">
                <div class="row m-0 p-0">
                    <div class="col-lg-2 text-bold">{{ $t('message.ModalProductFormTabMain.register_number') }}</div>
                    <div class="col-lg-10">
                        <div class="form-group">
                            <InputText placeholder="" v-model="entry.medicineRegisterNumber" :readonly="true" :borderBottom="true"/>
                        </div>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-lg-2 text-bold">{{ $t('message.ModalProductFormTabMain.ingredient') }}</div>
                    <div class="col-lg-10">
                        <div class="form-group">
                            <InputText placeholder="" v-model="entry.medicineIngredient" :readonly="true" :borderBottom="true"/>
                        </div>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-lg-2 text-bold">{{ $t('message.ModalProductFormTabMain.content') }}</div>
                    <div class="col-lg-10">
                        <div class="form-group">
                            <InputText placeholder="" v-model="entry.medicineContent" :readonly="true" :borderBottom="true"/>
                        </div>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-lg-2 text-bold">{{ $t('message.ModalProductFormTabMain.packing') }}</div>
                    <div class="col-lg-10">
                        <div class="form-group">
                            <InputText placeholder="" v-model="entry.medicinePacking" :readonly="true" :borderBottom="true"/>
                        </div>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-lg-2 text-bold">{{ $t('message.ModalProductFormTabMain.manufacturer') }}</div>
                    <div class="col-lg-10">
                        <div class="form-group">
                            <InputText placeholder="" v-model="entry.medicineManufacturer" :readonly="true" :borderBottom="true"/>
                        </div>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-lg-2 text-bold">{{ $t('message.ModalProductFormTabMain.country') }}</div>
                    <div class="col-lg-10">
                        <div class="form-group">
                            <InputText placeholder="" :borderBottom="true"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12" v-if="entry && entry.attributes">
                <h6 id="uTitle" class="font-weight-bold">
                    {{ $t('message.ModalProductFormTabMain.attribute') }}
                    <label class="text-danger px-0" v-if="this.param != this.$constant.status_isUpdate"></label>
                </h6>
                <table class="table table-index table-striped">
                    <tbody>
                        <template v-for="(attribute, index) in entry.attributes" :key="index">
                            <tr class="unitRowContent" style="height: 85px;">
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    <q-select :isCreateAttribute="true" :index="index" @showModalCreateAtt="showModalCreateAttribute(index)" 
                                        @onselectedAttribute="onselectedAttribute" :default-options="listAtrrubutes" :multiple="false" :modelValue="attribute.id" 
                                        :placeholder="$t('message.ModalProductFormTabMain.placeholder.attribute')" ref="changeUnit"/>
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    <QSelectTag @selectData="updateDataAttributeOption" :optionsProp="attribute.options" :index="index"/>
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3 align-middle">
                                    <!-- <i class="fas fa-trash-alt" @click.prevent="removeAttribute(index, attribute.productId)"></i>  -->
                                    <i class="fas fa-trash-alt" @click.prevent="showModalRemoveAttribute(index, attribute.productId)"></i> 
                                </th>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12" v-if="this.$route.query.action!='detail'">
                <button v-if="this.statusOption.variant" type="button" @click="addAttribute()" class="btn btn-save"><i class="fa fa-plus"/> {{ $t('message.ModalProductFormTabMain.button.attribute_plus') }}</button>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12">
                <h6 id="uTitle" class="font-weight-bold">
                    {{ $t('message.ModalProductFormTabMain.unit') }}
                    <label class="text-danger px-0" v-if="this.param != this.$constant.status_isUpdate"><strong>*</strong></label>
                </h6>
                <div class="cursor-pointer">
                    <label class="d-flex">
                        <input type="checkbox" value="1" v-model="entry.unitAutoPrice" class="mr-2"> {{ $t('message.ModalProductFormTabMain.auto_price') }}
                    </label>
                </div>
                <table class="table table-index table-striped">
                    <thead>
                        <tr>
                            <th class="unitTH vertical-align-top"></th>
                            <th class="unitTH vertical-align-top"></th>
                            <th class="unitTH text-center vertical-align-top">{{ $t('message.ModalProductFormTabMain.table.stt') }}</th>
                            <th class="unitTH vertical-align-top">{{ $t('message.ModalProductFormTabMain.table.unit_name') }}</th>
                            <th class="unitTH vertical-align-top">{{ $t('message.ModalProductFormTabMain.table.conversion_value') }}</th>
                            <th class="unitTH vertical-align-top">{{ $t('message.ModalProductFormTabMain.table.conversion_calculation') }}</th>
                            <th class="unitTH vertical-align-top">{{ $t('message.ModalProductFormTabMain.description') }}</th>
                            <th class="unitTH vertical-align-top">{{ $t('message.ModalProductFormTabMain.table.price') }}</th>
                            <th class="unitTH vertical-align-top"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(a, index) in productUnits" :key="(this.isUpdate ? a.id : a.index)" >
                            <tr v-if="!a.deleted" class="unitRowContent" style="height: 85px;">
                                <template v-if="a.isBase">
                                    <th class="text-center vertical-align-top pt-4 pb-3">
                                        <input name="isBase"
                                            @change="onBaseChange(a)"
                                            type="radio" :value="true"  v-model="a.isBase" class="mb-0">
                                    </th>
                                    <th class="vertical-align-top pt-4 pb-3">
                                        {{ $t('message.ModalProductFormTabMain.table.unit_main') }}
                                    </th>
                                </template>
                                <template v-else-if="!a.isBase">
                                    <th class="text-center vertical-align-top pt-4 pb-3">
                                        <input name="isBase"
                                            @change="onBaseChange(a)"
                                            type="radio" :value="true"  v-model="a.isBase" class="mb-0">
                                    </th>
                                    <th class="vertical-align-top pt-4 pb-3">
                                        {{ $t('message.ModalProductFormTabMain.table.unit_conversion') }}
                                    </th>
                                </template>
                                <th class="text-center vertical-align-top pt-4 pb-3">
                                    {{index+1}}
                                </th>
                                <th class="units vertical-align-top pt-3 pb-3">
                                    <!-- <InputSelect :options="units" :errors="a.errors.unitId" v-model="a.unitId" class="mb-0" @change="unitUpdated(a)"/> -->
                                    <q-select v-model="a.unitId" :readonly="(this.isCopy ? false : (!a.newUnit && entry.haveTransaction ? true : false))" :default-options="units" :multiple="false" :placeholder="$t('message.ModalProductFormTabMain.table.placeholder.unit_name')" @click="unitUpdated(a)" ref="changeUnit"/>
                                    <ErrorLabel :errors="a.errors.unitId"/>
                                    <span class="add-units-btn" @click="showModalUnitAdd(a)">
                                        <i class="fa fa-plus px-1" />
                                    </span>
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    <InputNumber :errors="a.errors.conversionValue" :min="1" :show-adjust="true" :max="100000" 
                                        @update:modelValue="value => conversionValueUpdated(a, value)"
                                        v-model="a.conversionValue" class="mb-0" v-if="!a.isBase" :formatNumber="true" :readonly="(this.isCopy ? false : (!a.newUnit && entry.haveTransaction ? true : false))"/>
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    <InputSelect :options="calculation" :errors="a.errors.conversionCalculation" :readonly="(this.isCopy ? false : (!a.newUnit && entry.haveTransaction ? true : false))" v-model="a.conversionCalculation" class="mb-0" v-if="!a.isBase" @change="priceUpdated(a)"/>
                                </th>
                                <th class="conversionValue vertical-align-top pt-4 pb-3">
                                    <template v-if="a.conversionCalculation == this.$constant.calculation_multiplication && !a.isBase">
                                        <template v-for="unit in units" :key="unit.id">
                                            <template v-if="a.unitId == unit.id">
                                                1 {{unit.name}} = {{a.conversionValue + ' ' + a.pUnit}}
                                            </template>
                                        </template>
                                    </template>
                                    <template v-else-if="a.conversionCalculation == this.$constant.calculation_division && !a.isBase">
                                        <template v-for="unit in units" :key="unit.id">
                                            <template v-if="a.unitId == unit.id">
                                                1 {{unit.name}} = 1/{{a.conversionValue + ' ' + a.pUnit}}
                                            </template>
                                        </template>
                                    </template>
                                </th>
                                <th class="price vertical-align-top pt-3 pb-3">
                                    <!-- <InputNumberFormat v-model="a.price" @input="value => priceUpdated(a,value)"  :errors="a.errors.price" class="mb-0" placeholder="Vui lòng nhập giá bán" :formatNumber="true" /> -->
                                    <InputNumber v-model="a.price" @input="value => priceUpdated(a,value)"  :errors="a.errors.price" class="mb-0" :placeholder="$t('message.ModalProductFormTabMain.table.placeholder.price')" :formatNumber="true" :isOrigin="true"/>
                                </th>
                                <th class="vertical-align-top pt-3 pb-3" >
                                    <!-- <button class="btn btn-sm btn-default mr-2" @click="print(a)" v-if="this.$route.query.action!='detail'">In tem mã</button> -->
                                    <a href="" @click.prevent="removeProductUnit(a)" class="btn btn-sm btn-danger" v-if="this.$route.query.action!='detail'">{{ $t('message.ModalProductFormTabMain.button.remove') }}</a>
                                </th>
                            </tr>
                        </template>

                    </tbody>
                </table>

            </div>
            <div class="col-lg-12" v-if="this.$route.query.action!='detail'">
                <button type="button" @click="addProductUnit()" class="btn btn-save"><i class="fa fa-plus"/> {{ $t('message.ModalProductFormTabMain.button.add_unit') }}</button>
            </div>
        </div>
        <div class="row mt-2" v-if="entry && entry.variants && entry.variants.length">
            <div class="col-lg-12">
                <h6 id="uTitle" class="font-weight-bold">
                    {{ $t('message.ModalProductFormTabMain.listVariant') }}
                </h6>
                <table class="table table-index table-striped">
                    <thead>
                        <tr>
                            <th class="unitTH">{{$t('message.LocationIndex.name')}}</th>
                            <th class="unitTH">{{ $t('message.DetailOrdersCard.prodUnit') }}</th>
                            <th class="unitTH">{{$t('message.ProductIndex.price')}}</th>
                            <th class="unitTH"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(variant, key) in entry.variants" :key="key">
                            <tr class="unitRowContent" style="height: 85px;">
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    {{ formatVariantName(variant) }}
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    {{ formatUnitName(variant) }}
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    <template v-for="(option, k) in variant" :key="k">
                                        <InputNumber v-if="option.isUnit" @input="value => updatePriceVariant(option.price, key)"  :id="'input-price-'+k+key" v-model="option.price"  class="mb-0" :placeholder="$t('message.ModalProductFormTabMain.table.placeholder.price')" :formatNumber="true"/> 
                                    </template>
                                </th>
                                <th class="conversionValue vertical-align-top pt-3 pb-3">
                                    <i class="fas fa-trash-alt" @click.prevent="removeVariant(key)"></i> 
                                </th>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="">
            <ModalProductBarcode ref="productBarcode"></ModalProductBarcode>
        </div>
    </div>
</template>
<script>
import InputText from "../../../components/forms/InputText";
import InputNumber from "../../../components/forms/InputNumberCustom";
// import InputNumber from "../../../components/forms/InputNumber";
import QSelect from "../../../components/QSelect";
import QSelectTag from "../../../components/QSelectTag";
import InputTextArea from "../../../components/forms/InputTextArea";
import InputFileManager from "../../../components/forms/InputFileManager";
import ErrorLabel from "../../../components/ErrorLabel";
import store from "../../../store";
import storeModul from "@/storeModul";
import InputSelect from "../../../components/forms/InputSelect";
import InputNumberFormat from "../../../components/forms/InputNumberFormat";
import ModalProductBarcode from "./ModalProductBarcode";
import {$alert, $get, clone, cloneObject, updateTreeNode} from "@/lib/utils";
import QTreeSelectInput from "@/components/qtreeselect/QTreeSelectInput";
import emitter from "@/lib/emitter";
import Treeselect from 'vue3-treeselect'
import SelectSearch from "@/components/SelectSearch.vue";
import MedicineSearch from "@/components/MedicineSearch.vue";
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ProductFormTabMain",
    props: [
        'onDisabled', 'type'
    ],
    components: {
        QTreeSelectInput,
        ModalProductBarcode,
        InputNumberFormat,
        InputSelect,
        ErrorLabel,
        InputFileManager, InputTextArea, QSelect, InputNumber, InputText, Treeselect,
        SelectSearch,
        MedicineSearch,
        QSelectTag,
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            // disable: this.$emit('disabled'),
            param: '',
            listAtrrubutes: [],
            listAttributeOptios: [],
            errors: {},
            currentBaseAttribute: null,
            isDataLoaded: false,
            isUpdate: null,
            productUnits: [],
            units: [],
            branches: globalConfig.branches,
            branchId: globalConfig.branchIds,
            categoryTree: [],
            routeOfUse: [],
            prdCharateristic:{
                name:[]
            },
            entry: {
                branchIDs: [],
                image: [],
                weight: null,
                categoryId: null,
                hsCode: '',
                taxRate: 0,
                attributes: [],
                lifeTime: 0,
            },
            default: null,
            first: true,
            calculation: [
                // { id: 0, name: this.$t('message.ModalProductFormTabMain.button.multiplication'),},                
                { id: 1, name: this.$t('message.ModalProductFormTabMain.button.division'),},                
            ],
            unitBase: '',
            currentUnitQuickAdd: {},
            taxRate: this.$constant.taxRate,
            isCopy: false,

            statusOption: {
                consignment: null,
                variant: null,
            }
        }
    },
    methods: {
        onselectedAttribute(obj) {
            if (!this.entry.attributes) {
                this.entry.attributes[obj.index] = {
                    id: obj.modelValue,
                    name: null,
                    options: [],
                };
                return;
            }
            let count = 0;
            let indexCurent = 0;
            for (let index = 0; index < this.entry.attributes.length; index++) {
                const att = this.entry.attributes[index];
                if (att.id == obj.modelValue) {
                    count++;
                    indexCurent = index;
                }
                if (count > 0) {
                    if (indexCurent != obj.index) {
                        this.entry.attributes.splice(obj.index, 1)
                    }
                    $alert({code: 500, message: this.$t('message.common.existAttribute')});
                    this.$forceUpdate();
                    return
                }
            }
            this.entry.attributes[obj.index] = {
                id: obj.modelValue,
                name: null,
                options: [],
            };
            this.mixVariantAndUnit();
        },
        removeVariant(key) {
            if (!confirm(this.$t('message.common.removeVarriant'))) {
                return;
            }
            this.entry.variants.splice(key, 1);
        },
        formatVariantName(attributes) {
            let attributeName = [];
            for (let index = 0; index < attributes.length; index++) {
                const attribute = attributes[index];
                if (!attribute.isUnit) {
                    attributeName.push(attribute.name); 
                }
            }
            return attributeName.join(' - ');
        },
        formatUnitName(attributes) {
            for (let index = 0; index < attributes.length; index++) {
                const attribute = attributes[index];
                if (attribute.isUnit) {
                    return attribute.name;
                }
            }
            return '';
        },
        async mixVariantAndUnit() {
            if (!this.entry.attributes || !this.entry.attributes.length){
                this.entry.variants = [];
                return;
            } 
            let listUnits = [];
            for (let index = 0; index < this.productUnits.length; index++) {
                const unit = this.productUnits[index];
                if (!unit['deleted'] && unit['unitId']) {
                    listUnits.push({
                        id: unit.unitId,
                        name: unit.unitName,
                        isUnit: true,
                        price: unit.price ?? 0,
                    });
                }
            }
            if (!listUnits.length || !this.entry.attributes){
                this.entry.variants = [];
                return;
            }
            let listAttOption = [listUnits];
            for (let index = 0; index < this.entry.attributes.length; index++) {
                const attr = this.entry.attributes[index];
                let currentAtt = [];
                if (attr.options.length) {
                    for (let key = 0; key < attr.options.length; key++) {
                        const option = attr.options[key];
                        currentAtt.push({
                            attributeId: attr.id,
                            name: option,
                        });
                    }
                    listAttOption.push(currentAtt);
                }
            }
            const variants = [];
            function combine(current, index) {
                if (index === listAttOption.length) {
                    variants.push([...current]);
                    return;
                }
                for (let i = 0; i < listAttOption[index].length; i++) {
                    current.push(listAttOption[index][i]);
                    combine(current, index + 1);
                    current.pop();
                }
            }
            combine([], 0);
            this.entry.variants = clone(variants);
        },
        showModalRemoveAttribute(index, productUnitId) {
            storeModul.dispatch('productStore/setDataRemoveAtrribute', {index: index, productUnitId: productUnitId});
            storeModul.dispatch('productStore/setShowModalConfirnRemoveAtrribute', true);
        },
        removeAttribute() {
            const that = this;
            if (!that.entry.attributes) return;
            that.entry.attributes.splice(this.indexAttributeRemove, 1);
            if (that.productUnitIdAttributeRemove) {
                if (!that.entry.listProductVariantIdRemove) {
                    that.entry.listProductVariantIdRemove = [];
                }
                that.entry.listProductVariantIdRemove.push(this.productUnitIdAttributeRemove);
            }
            that.mixVariantAndUnit();
            that.clearStore();
        },
        clearStore() {
            storeModul.dispatch('productStore/setDataRemoveAtrribute', {index: null, productUnitId: null});
            storeModul.dispatch('productStore/setStatusConfirmRemoveAtrribute', false);
        },
        updateDataAttributeOption(data) {
            if (!data || data.index < -1 || !this.entry.attributes[data.index]) return;
            this.entry.attributes[data.index].options = data.data;
            this.mixVariantAndUnit();
        },
        updateValue(v){            
            this.entry.taxRate = v.model
        },
        showModalCategoryAdd(){
            emitter.emit('showModalCategoriesAdd');           
        },
        showModalCreateRouteOfUse(){
            emitter.emit('showModalCreateRouteOfUse');           
        },
        showModalUpdateRouteOfUse(id){
            emitter.emit('showModalCreateRouteOfUse', id);           
        },
        showModalUnitAdd(a){
            this.currentUnitQuickAdd = {};
            this.currentUnitQuickAdd = cloneObject(a);
            this.typeEditAdd = "ADD";
            this.idUnit = null;
            this.quickAdd = true;
            emitter.emit('showModalUnitAdd');
        },
        conversionValueUpdated(a, conversionValue) {
            setTimeout(() => {
                this.$emit('onValidate');
            }, 50);

            if (!this.entry.unitAutoPrice || a.isBase) {
                return;
            }

            // const base = this.productUnits.find(a => a.isBase);
            // if (base) {
            //     if(this.entry.unitAutoPrice){
            //         this.setPrice(a, base, conversionValue);
            //     }
            // }
            
            if(this.entry.unitAutoPrice){
                this.productUnits.forEach(unit => {
                    if(unit?.index == a?.index || unit?.id == a?.id){
                        a.conversionValue = conversionValue;
                    }
                    this.setPrice(unit);
                })
            }
        },
        unitUpdated(a){
            if (a.isBase) {
                this.setUnitBase(a);
            }
            this.units.forEach(unit => {
                if(unit.id == a.unitId){
                    a.unitName = unit.name;
                }
            })
            this.addParentUnit();
            this.mixVariantAndUnit();
        },
        typeUpdated(){
            emitter.emit("changeType", this.entry.type);
        },
        updatePriceVariant(price, key) {
            if (!price) return; 
            let variants = clone(this.entry.variants);
            for (let index = 0; index < variants[key].length; index++) {
                if (variants[key][index].isUnit) {
                    variants[key][index].price = clone(price);
                    break
                }
            }
            this.entry.variants = variants;
            return price;
        },
        priceUpdated(a) {
            setTimeout(() => {
                this.$emit('onValidate');
            }, 50);

            if (a.isBase && this.entry.unitAutoPrice) {
                this.entry.basePrice = a.price;
                this.productUnits.forEach(pu => {
                    if (!pu.isBase) {
                        this.setPrice(pu, a);
                    }
                })
            }else if(a.isBase && !this.entry.unitAutoPrice){
                this.entry.basePrice = a.price;
            }else if(!a.isBase && this.entry.unitAutoPrice){
                const units = this.productUnits.find(pa => pa.isBase);
                if (units) {
                    this.setPrice(a, units);
                }
            }

            this.mixVariantAndUnit();
        },
        setPrice(currentUnit, baseUnit, conversionValue){
            let pPrice = this.productUnits.find(pa => pa.unitName == currentUnit.pUnit);
            let price;
            if(pPrice){
                price = +pPrice.price;
            }else{
                price = +currentUnit.price;
            }
            if(currentUnit.conversionCalculation == this.$constant.calculation_multiplication){
                currentUnit.price = price * currentUnit.conversionValue;
            }else{
                currentUnit.price = price / currentUnit.conversionValue;
            }
        },
        your_normalizer (node) {
            return {
                children: node.children && node.children.length ? node.children : 0
            }
        },

        onBaseChange(a) {
            if (this.productUnits && this.productUnits.length > 0) {
                this.productUnits.forEach(pa => {
                    if (!pa.deleted) {
                        if (pa.unitId === a.unitId && a.unitId != null) {
                            pa.isBase = true;
                            // pa.conversionValue = 1;
                            this.entry.basePrice = pa.price;
                            this.setUnitBase(a);
                        }else if(pa.unitId === a.unitId && a.unitId == null && pa?.index == a?.index){
                            pa.isBase = true;
                            // pa.conversionValue = 1;
                            this.entry.basePrice = pa.price;
                            this.unitBase = null;
                        }else{
                            pa.isBase = false;
                        }
                    }
                });
            }
            this.sortProductUnit();
            this.addParentUnit();
            this.$emit('onValidate')
        },
        async removeProductUnit(attribute) {
            if (!confirm(this.$t('message.common.removeUnit'))) {
                return;
            }
            // Kiểm tra sản phẩm đã phát sinh giao dịch chưa
            if(this.entry.id && attribute.productId && !this.isCopy){
                const res = await $get('/v1/products/checkTransaction', {productId: attribute.productId, productUnitId: attribute.id});            
                if (res && res.status == 201) {                
                    $alert(res);
                    return;
                }
                else{
                    this.remove(attribute);
                }
            }else{
                this.remove(attribute);
            }
        },
        remove(attribute){
            attribute.deleted = true;
            if (attribute.isBase) {
                const first = this.productUnits.find(pa => !pa.deleted);
                
                if (first) {
                    first.conversionValue = 1;
                    first.isBase = true;
                    this.entry.basePrice = first.price;
                    this.setUnitBase(first);
                }
            }
            this.sortProductUnit();
            this.addParentUnit();
            this.mixVariantAndUnit();
        },
        async getListAttributes(data = null) {
            const res = await $get('/v1/products/get-list-attributes');
            if (res && res.result) {
                this.listAtrrubutes = res.result;
                if (data) {
                    await this.activeAtrribute(data);
                }
            }
        },
        async activeAtrribute(data) {
            let that = this;
            if (!that.entry.attributes) {
                that.entry.attributes = [];
            }
            if (!that.entry.attributes[data.index]) {
                return;
            }
            that.entry.attributes[data.index].id = data.attId;
        },
        addAttribute() {
            let attribute = {
                id: null,
                name: null,
                options: [],
            };
            if (!this.entry.attributes) {
                this.entry.attributes = [];
            }
            this.entry.attributes.push(attribute);
        },
        addProductUnit() {
            let isBase = false;
            if (this.productUnits.filter(pa => !pa.deleted).length === 0 || this.productUnits.length == 0) {
                isBase = true;
            }
            this.productUnits.push({
                isBase: isBase,
                unitId: null,
                conversionValue: 1,
                conversionCalculation: 1,
                price: 0,
                deleted: false,
                errors: {},
                index: this.productUnits.length+1,
                newUnit: true
            })
            this.sortProductUnit();
            const units = this.productUnits.find(pa => pa.isBase);
            if (units) {
                this.setUnitBase(units);
            }
            this.addParentUnit();
        },
        setUnitBase(a){
            this.units.forEach(unit => {
                if(unit.id == a.unitId){
                    this.unitBase = unit.name;
                }
            })
        },
        print(pUnit) {
            const product = cloneObject(this.entry);
            product.productUnit = pUnit;

            this.$refs.productBarcode.print(product);
        },
        showModalCreateAttribute(index) {
            emitter.emit('showModalCreateAttribute', index);  
        },
        async loadTreeCategory() {
            const res = await $get('/v1/categories/getTreeCategories', {});
            await updateTreeNode(res.result.tree)
            this.categoryTree = res.result.tree;
            emitter.emit('loadQTreeSelect', this.categoryTree);
        },

        // Load đường dùng
        async loadRouteOfUse (){
            const res = await $get('/v1/sync-medicine/all-route-of-use', {});
            this.routeOfUse = res.result;
        },
        async loadSetting ()
        {
            const res = await $get('/v1/setting-store/get-options', {action: 'product'});
            this.statusOption.consignment = this.statusOption.variant = false;
            res.result.forEach(element => {
                
                if (element.id == 2 && element.status == this.$constant.active) {
                    this.statusOption.variant = true;
                }
                if (element.id == 7 && element.status == this.$constant.active) {
                    this.statusOption.consignment = true;
                }
            });
        },
        async loadUnit(a = null){
            const res = await $get('/v1/units/get-data');
            this.units = res.data.entries;
            if(a != null){
                this.currentUnitQuickAdd.unitId = a.id;
                this.unitUpdated(this.currentUnitQuickAdd);
                if (this.productUnits && this.productUnits.length > 0) {
                    this.productUnits.forEach(pa => {
                        if (!pa.deleted) {
                            if(this.currentUnitQuickAdd.id && pa.id && this.currentUnitQuickAdd.id == pa.id){
                                pa.unitId = this.currentUnitQuickAdd.unitId;
                            }else if(this.currentUnitQuickAdd.index && pa.index && this.currentUnitQuickAdd.index == pa.index){
                                pa.unitId = this.currentUnitQuickAdd.unitId;
                            }
                        }
                    });
                }
            }
        },
        async addParentUnit(){
            let pUnit = null;
            this.productUnits.forEach(pa => {
                pa.pUnit = pUnit;
                pUnit = pa?.unitName;
            });
        },
        sortProductUnit(){
            this.productUnits = this.productUnits.sort(function(a, b){
                if (a.isBase && !b.isBase) {return -1;}
                if (!a.isBase && b.isBase) {return 1;}
                return 0;
            });
            this.productUnits = this.productUnits.sort((a, b) => {
                if (a.parent_id === null) return -1;
                if (b.parent_id === null) return 1;
                if (a.parent_id === b.id) return 1;
                if (b.parent_id === a.id) return -1;
                return a.id - b.id;
            });
        },
        selectMedicine(medicine){
            if(medicine){
                this.entry.medicineCode = medicine.data.code;
                this.entry.medicineRegisterNumber = medicine.data.registerNumber;
                this.entry.medicineIngredient = medicine.data.ingredient;
                this.entry.medicineContent = medicine.data.content;
                this.entry.medicinePacking = medicine.data.packing;
                this.entry.medicineManufacturer = medicine.data.manufacturer;
                this.entry.medicineId = medicine.data.id
            }
        }
    },
    created() {
        emitter.on("loadDataAttribute", (data) => {
            this.getListAttributes(data);
        });
        emitter.on("clearMain", () => {
            this.entry = {
                branchIDs: [],
                image: [],
                weight: null,
                categoryId: null,
                isConsignment: false
            }
            this.productUnits = [];
            this.param = null;
            this.isUpdate = null;
            this.errors = {};
            // if(this.default){
            //     this.entry = this.default
            //     this.first = true
            // }
        });
        emitter.on("clearMainAll", () => {
            this.entry = {
                branchIDs: [],
                image: [],
                weight: null,
                categoryId: null,
            }
            this.productUnits = [];
            this.param = null;
            this.errors = {};
        });
        emitter.on("changeParam", ()=>{
            this.param = this.$constant.status_isUpdate;
        });
        emitter.on("changeIsUpdate", ()=>{
            this.isUpdate = this.$constant.status_isUpdate;
        });
        emitter.on("quickUpdateUnit", (a)=>{
            this.loadUnit(a);
        });
        emitter.on("getListTreeCategory", ()=>{
            this.loadTreeCategory();
            this.loadRouteOfUse();
            this.loadSetting();
        });
        emitter.on("getRouteOfUse", ()=>{
            this.loadRouteOfUse();
        });
        emitter.on("removeRouteOfUse", (id)=>{
            if(this.entry && this.entry.routeOfUseIds){
                if(this.entry.routeOfUseIds.includes(id)){
                    this.entry.routeOfUseIds = this.entry.routeOfUseIds.filter(item => item !== id)
                }
            }
        });
        this.getListAttributes();
        
    },
    mounted() {

        if(JSON.parse(localStorage.getItem('productID'))) this.disable = true;
        else this.disable = false;

        if(this.$route.query.type==1) this.param = this.$route.query.type
        else {
            if(this.$route.query.id!="") this.param = this.$route.query.id
        }
        if(this.$route.path === '/settings/store') this.param = 1
        document.title = this.$t('message.common.productInformation');
    },
    computed: {
        ...mapGetters({
            statusConfirmRemoveAtrribute: 'productStore/statusConfirmRemoveAtrribute',
            indexAttributeRemove: 'productStore/indexAttributeRemove',
            productUnitIdAttributeRemove: 'productStore/productUnitIdAttributeRemove',
        }),
        dulieu () {
            return this.show && [
                { 'label': 'label1', 'value': 'value1' },
                { 'label': 'label2', 'value': 'value2' },
                { 'label': 'label3', 'value': 'value3' }
            ]
        },
    },
    watch: {
        'entry': function(newVal){
            let defaultEntry = JSON.stringify({branchIDs: [],image: [],categoryId: null})
            if(this.first && !(JSON.stringify(newVal) === defaultEntry)){
                this.default = {...newVal}
                this.first = false
            }
            if(newVal.isConsignment){
                this.entry.isConsignment = true;
            }
        },
        'productUnits': {
            handler(newUnit){
                newUnit.forEach(el => {
                    this.entry.weight = el.weight;
                    if(el.isBase){
                        this.unitBase = el.unitName;
                    }
                });
                this.sortProductUnit();
                this.addParentUnit();
            },
            deep: true
        },

        statusConfirmRemoveAtrribute(status) {
            if (status) {
                this.removeAttribute();
            }
        }
    }
}
</script>

<style scoped>
    .add-q-btn {
        position: absolute;
        right: 20px;
        top: 10px;
        color: #ccc;
        display: block;
    }
    .add-units-btn {
        position: absolute;
        right: 18px;
        top: 27px;
        color: #ccc;
    }
    .units{
        position: relative;
        max-width: 200px;
    }

</style>
