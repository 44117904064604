import NProgress from 'nprogress';
import "nprogress/nprogress.css";
import "toastr/toastr.scss";
import config from "../config";
import {deepFreeze} from "./deepfreeze";
import store from "../store";
const BASE_API_URL = config.App.v1ApiUrl;
const BASE_API_SHOPEE_URL = config.ApiShopee.v2ApiShopeeUrl;
const language = localStorage.getItem('language') ?? 'vi';
const deviceId = localStorage.getItem('deviceIdPOS') ?? '';
import toastr from 'toastr';
import moment from "moment";
import router from "@/router/index";
import emitter from "@/lib/emitter"; 
export const DISCOUNT_TYPE_PERCENT = 2;
export const DISCOUNT_TYPE_VND = 1;

export const TYPE_INVOICE = 'invoice';
export const TYPE_ORDER = 'order';
export const TYPE_DELIVERY = 'delivery';
export const TYPE_RETURN = 'return';
export const TYPE_REQUEST = 'request';
export const TYPE_TRANSFER = 'transfer';
export const TYPE_IMPORT = 'import';
export const TYPE_EXPORT = 'export';
export const TYPE_REFUND = 'refunds';
export const TYPE_MULTI_ORDER = 'multiOrder';
export const ACTION_CREATE = 'create';
export const ACTION_UPDATE = 'update';
export const ACTION_ORDER_PROCESSING = 'orderProcessing';
export const ACTION_UPDATE_ORDER = 'updateOrder';
export const ACTION_UPDATE_INVOICE = 'updateInvoice';
export const ACTION_RETURN_INVOICE = 'returnInvoice';
export const ACTION_RETURN_WITHOUT_INVOICE = 'returnWithoutInvoice';

export const TYPE_OUT_OF_DATE = 1;
export const TYPE_NOT_OUT_OF_DATE = 2;

export function chunk(input: Array<any>, chunkSize: number): Array<any> {
    const result = [];
    let i,j,temparray;
    const chunk = chunkSize;

    for (i=0,j=input.length; i<j; i+=chunk) {
        temparray = input.slice(i,i+chunk);
        result.push(temparray);
    }

    return result;
}

export function numberFormat(v: any ,n: any = 0, x = 3, s= ',', c = null ): string {
    if (!v) {
        return '0';
    }

    v = Number(v);
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = v.toFixed(Math.max(0, ~~n));

    const r = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    return r;
}

export function rand(min: number, max: number) : number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function arrayRand(arr: Array<any>): any {
    return arr[Math.floor(Math.random() * arr.length)]
}

export function range(len: number, step: number = 1, start: number = 0): Array<number> {
    const arr = [];
    for (let i = start; i < len; i+= step) {
        arr.push(i);
    }

    return arr;
}

export function clone(item: any): any {

    if (!item) { return item; } // null, undefined values check

    const types = [ Number, String, Boolean ];
    let result: any;

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function(type) {
        if (item instanceof type) {
            result = type( item );
        }
    });

    if (typeof result == "undefined") {
        if (Object.prototype.toString.call( item ) === "[object Array]") {
            result = [];
            item.forEach(function(child: any, index: number) {
                result[index] = clone( child );
            });
        } else if (typeof item == "object") {
            // testing that this is DOM
            if (item.nodeType && typeof item.cloneNode == "function") {
                result = item.cloneNode( true );
            } else if (!item.prototype) { // check that this is a literal
                if (item instanceof Date) {
                    result = new Date(item);
                } else {
                    // it is an object literal
                    result = {};
                    for (const i in item) {
                        result[i] = clone( item[i] );
                    }
                }
            } else {
                result = item;
            }
        } else {
            result = item;
        }
    }

    return result;
}

export function cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export function createDeepFreeze(object: any): any {
    const o = clone(object);
    return deepFreeze(o);
}

export function stringTruncate(str: string, length: number) {
    if (!str) {
        return '';
    }

    const dots = str.length > length ? '...' : '';
    return str.substring(0, length)+dots;
}

export function debounce(func: any, wait = 500) {
    let timeout: any;
    return (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // @ts-ignore
            func.apply(this, args)
        }, wait)
        // timeout = setTimeout(func, wait);
    };
}

export function isStringNullOrWhiteSpace( value: any) {

    if (value== null) return true;

    return value.replace(/\s/g, '').length === 0;
}

export function parseQuery(queryString: any) {
    const query: any = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}
export async function $get(uri: string, params: any = {}, progress = true): Promise<ApiBaseResponse> {
    if (progress) {
        NProgress.start();
    }

    let url;
    if (Object.keys(params).length) {
        if (uri.indexOf('?') >= 0) {
            url =  uri + '&' + buildQuery(params);
        } else {
            url =  uri + '?' + buildQuery(params);
        }
    } else {
        url = uri;
    }

    url = BASE_API_URL + url;

    const options: any = {
        headers: {
            'X-Requested-With': 'fetch',
            'Content-Type': 'application/json',
            'X-Token': store.state.Auth?.token || '',
            'X-Branch-Id': store.state.CurrentBranch?.id.toString() || '',
            'Language': language,
            'Deviceid': deviceId
        },
    };

    return fetch(url, options).then(async response => {
        if (progress) {
            NProgress.done();
        }

        if (response.status === 422) {
            const messages = await response.json();
            return  {
                'code' : response.status,
                'status': false,
                'messages': messages,
            }
        }

        if (response.status === 401) {
            store.commit('RemoveAuth');
            location.replace('/xadmin/login');
            return;
        }
        
        if (response.status === 403) { 
            toastr.error('Bạn không có quyền xem thông tin này');           
            router.push({ name: 'Home'});          
            emitter.emit("offLoading");
            return;
        }
        if (response.status === 404) { 
            router.push({ name: 'Home'});          
            emitter.emit("offLoading");
            return;
        }
        if (response.status === 500) {
            router.push({ name: 'ErrorsRequest' });
            return;
        }
        
        if (response.status !== 200) {
            response.text().then(v => {
                console.error(v);
            })
            return Promise.reject('Error from server. HTTP code: ' + response.status)
        }

        const json:any = await response.json();        
        if (json.code === 401) {
            store.commit('SetShowPermissionRequired', true);
            store.commit('PushRequiredPermissions', json.requiredPermissions);
            return Promise.reject('Bạn không có quyền truy cập trang này');
        }

        return json;
    });
}

export async function $getApiShopee(uri: string, params: any = {}, progress = true): Promise<ApiBaseResponse> {
    if (progress) {
        NProgress.start();
    }

    let url;
    if (Object.keys(params).length) {
        if (uri.indexOf('?') >= 0) {
            url =  uri + '&' + buildQuery(params);
        } else {
            url =  uri + '?' + buildQuery(params);
        }
    } else {
        url = uri;
    }

    url = BASE_API_SHOPEE_URL + url;

    const options: any = {
        headers: {
            'X-Requested-With': 'fetch',
            'Content-Type': 'application/json',
            'X-Token': store.state.Auth?.token || '',
            'X-Branch-Id': store.state.CurrentBranch?.id.toString() || ''
        },
    };

    return fetch(url, options).then(async response => {
        if (progress) {
            NProgress.done();
        }

        if (response.status !== 200) {
            response.text().then(v => {
                console.error(v);
            })
            return Promise.reject('Error from server. HTTP code: ' + response.status)
        }

        const json:any = await response.json();
        if (json.code === 401) {
            store.commit('SetShowPermissionRequired', true);
            store.commit('PushRequiredPermissions', json.requiredPermissions);
           
            return Promise.reject('Bạn không có quyền truy cập trang này');
        }

        return json;
    });
}
export async function $post(uri: string, params = {}, progress = true): Promise<ApiBaseResponse> {

    if (progress) {
        NProgress.start();
    }

    const url = BASE_API_URL + uri;

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'fetch',
            'X-Token': store.state.Auth?.token || '',
            'X-Branch-Id': store.state.CurrentBranch?.id.toString() || '',
            'Language': language,
            'Deviceid': deviceId
        },
        body: JSON.stringify(params),
    })
        .then(async (response) => {
            if (progress) {
                NProgress.done();
            }
            if (response.status === 403) {    
                // const res:any = await response.json();        
                // router.push({ name: res.routerName});  
                toastr.error('Bạn không có quyền xem thông tin này');      
                router.push({ name: 'Home' });   
                 
                emitter.emit("offLoading");             
                return;
            }
            if (response.status === 404) {  
                // const res:any = await response.json();                 
                // router.push({ name: res.routerName});          
                router.push({ name: 'Home' }); 
                emitter.emit("offLoading");
                return;
            }
            if (response.status === 401) {
                store.commit('RemoveAuth');
                location.replace('/xadmin/login');
                return;
            }
            if (response.status === 422) {
                const messages = await response.json();
                return  {
                    'code' : response.status,
                    'status': false,
                    'messages': messages,
                }
            }
            if (response.status !== 200) {
                response.text().then(v => {
                    console.error(v);
                })
                return Promise.reject('Error from server. HTTP code: ' + response.status)
            }

            const json:any = await response.json();
            if (json.code === 401) {
               store.commit('SetShowPermissionRequired', true);
               store.commit('PushRequiredPermissions', json.requiredPermissions);
               return Promise.reject('Bạn không có quyền truy cập trang này');
            }

            return json;
        })
}

export function $upload(uri: string, files: Array<File>, params = {}) {
    const url = BASE_API_URL + uri;
    
    
    const formData = new FormData();

    forEach(params, (v: any, k: string) => {
        formData.append(k ,v);
    });

    for (let i = 0; i< files.length; i++) {
        formData.append('file_' + i, files[i]);
    }

    return fetch(url, {
        method: 'POST',        
        body: formData,
        headers: {
            'X-Requested-With': 'fetch',
            'X-Token': store.state.Auth?.token || '',
            'X-Branch-Id': store.state.CurrentBranch?.id.toString() || '',
            'Language': language,
            'Deviceid': deviceId
        }
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error('Error:', error);
        });
}

/**
 * Encode object to x-www-form-urlencoded
 * @param data
 */
export function buildQuery(data: any): string {
    if (typeof data !== 'object') {
        return '';
    }

    const queries = [];
    for (const k in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data[k]) {
            queries.push(k + '=' + encodeURIComponent(data[k]));
        }
    }
    return queries.join('&');
}

export function thumbUrl(url: string): string {


    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }

    return config.App.imageBaseUrl + url;
}

export function uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isEmpty(obj: any): boolean {
    if (!obj) {
        return true;
    }

    for (const k in obj) {
        return false;
    }

    return true;
}

export function ucfirst (string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function stringFormat(str: any, args: Array<number|string>) {
    return str.replace(/{(\d+)}/g, function(match:any, number:any) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
}


export function rtrim (str: string, charlist: string): string {
    //  discuss at: https://locutus.io/php/rtrim/
    // original by: Kevin van Zonneveld (https://kvz.io)
    //    input by: Erkekjetter
    //    input by: rem
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Brett Zamir (https://brett-zamir.me)
    //   example 1: rtrim('    Kevin van Zonneveld    ')
    //   returns 1: '    Kevin van Zonneveld'

    charlist = !charlist ? ' \\s\u00A0' : (charlist + '')
        .replace(/([[\]().?/*{}+$^:])/g, '\\$1')

    const re = new RegExp('[' + charlist + ']+$', 'g')

    return (str + '').replace(re, '')
}

export function ltrim (str: string, charlist: string): string {
    //  discuss at: https://locutus.io/php/ltrim/
    // original by: Kevin van Zonneveld (https://kvz.io)
    //    input by: Erkekjetter
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    //   example 1: ltrim('    Kevin van Zonneveld    ')
    //   returns 1: 'Kevin van Zonneveld    '

    charlist = !charlist ? ' \\s\u00A0' : (charlist + '')
        .replace(/([[\]().?/*{}+$^:])/g, '$1')

    const re = new RegExp('^[' + charlist + ']+', 'g')

    return (str + '')
        .replace(re, '')
}

export function trim (str: string, charlist: string): string {
    if (!str) {
        return '';
    }
    //  discuss at: https://locutus.io/php/trim/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: mdsjack (https://www.mdsjack.bo.it)
    // improved by: Alexander Ermolaev (https://snippets.dzone.com/user/AlexanderErmolaev)
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Steven Levithan (https://blog.stevenlevithan.com)
    // improved by: Jack
    //    input by: Erkekjetter
    //    input by: DxGx
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    //   example 1: trim('    Kevin van Zonneveld    ')
    //   returns 1: 'Kevin van Zonneveld'
    //   example 2: trim('Hello World', 'Hdle')
    //   returns 2: 'o Wor'
    //   example 3: trim(16, 1)
    //   returns 3: '6'

    let whitespace = [
        ' ',
        '\n',
        '\r',
        '\t',
        '\f',
        '\x0b',
        '\xa0',
        '\u2000',
        '\u2001',
        '\u2002',
        '\u2003',
        '\u2004',
        '\u2005',
        '\u2006',
        '\u2007',
        '\u2008',
        '\u2009',
        '\u200a',
        '\u200b',
        '\u2028',
        '\u2029',
        '\u3000'
    ].join('');

    let l = 0;
    let i = 0;
    str += '';

    if (charlist) {
        whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
    }

    l = str.length
    for (i = 0; i < l; i++) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(i)
            break
        }
    }

    l = str.length
    for (i = l - 1; i >= 0; i--) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(0, i + 1)
            break
        }
    }

    return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
}

export function mb_strlen(str: any) {
    if (typeof str === 'string') {
        return str.length;
    }

    return 0;
}


export function arr(value: any) : Array<any> {
    if (Array.isArray(value)) {
        return value;
    }

    return value ? [value] : [];
}

export function flip(array: Array<any>, fillValue: any = null) {
    if (!Array.isArray(array)) {
        array = arr(array);
    }

    const n = array.length;
    const obj: any = {};
    if (fillValue) {
        for (let i = 0; i < n; i++) {
            obj[array[i]] = fillValue;
        }
    } else {
        for (let i = 0; i < n; i++) {
            obj[array[i]] = i;
        }
    }


    return obj;
}

export function percentage(partialValue: any, totalValue: any, max: number = 1): number {
    if (!totalValue) {
        return 0;
    }

    return max * ((100 * partialValue) / totalValue);
}

export function sleep(miliseconds: number): Promise<boolean> {
    return new Promise(((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, miliseconds)
    }))
}

/**
 *
 * @param arr Array
 * @param key string
 * @param value string | null
 * @returns {{}}
 */
export function pluck(arr: any, key = 'id', value: any = null) {
    if (!Array.isArray(arr)) {
        return {};
    }

    const map: any = {};
    const len = arr.length;
    for (let i = 0; i < len; i++) {
        map[arr[i][key]] = value === null ? arr[i] : arr[i][value];
    }

    return map;
}

export function getValidPage(page: any): number {
    const p = parseInt(page);
    if (!p) {
        return 1;
    }

    if (p < 1) {
        return 1;
    }

    return p;
}

export function intVal(n: any): number {
    const a = parseInt(n);

    return a ? a : 0;
}

export function getDefaultImage(): string {
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
}

export function boolVal(a: any): boolean {
    return !!a;
}

export function throttle (callback: any, limit = 500) {
    let waiting = false;                      // Initially, we're not waiting
    return function (...params: Array<any>) {                      // We return a throttled function
        if (!waiting) {                       // If we're not waiting
            callback.call(params);  // Execute users function
            waiting = true;                   // Prevent future invocations
            setTimeout(function () {          // After a period of time
                waiting = false;              // And allow future invocations
            }, limit);
        }
    }
}

let jsonData: any;

export function setJsonData(data: any ) {
    jsonData = data;
}

export function getJsonData(key: string, defaultValue: any = null) {
    try {
        return jsonData[key] ? jsonData[key]: defaultValue;
    } catch (e) {
        console.warn('getJsonData failed: ', e);
    }

    return defaultValue;
}

export function parseIntEx(value: any): number {
    if (!value) {
        return 0;
    }

    let ret;
    if (typeof value === 'string') {
        value = value.replace(/,/g, '');
        ret = parseInt(value);
    } else {
        ret = parseInt(value);
    }

    return ret ? ret : 0;
}

export function getOption(key: string, defaultValue: any = null) {

    try {
        return jsonData.options[key] ? jsonData.options[key]: defaultValue;
    } catch (e) {
        console.warn('getOption failed: ', e);
    }

    return defaultValue;
}

export function $alert(res: ApiBaseResponse) {
    if (res.code === 200) {
        toastr.success(res.message);
    } else {
        toastr.error(res.message);
    }
}
export function $msg(res: ApiBaseResponse, message: any) {
    if (res.code === 200) {
        toastr.success(message);
    } else {
        toastr.error(message);
    }
}

export function toastCutomBottom(message: any, type: "info" | "success" | "warning") {
    toastr["options"] = {
        "debug": false,
        "positionClass": "toast-bottom-right",
        "timeOut": 5000,
        "extendedTimeOut": 1000
    }
    toastr[type](message)
}

export function scrollToErrors() {
    setTimeout(() => {
        scrollToElement('.error-label');
    }, 100);
}

export function scrollToElement(element: any) {
    const $el:any =  $(element);

    if ($el.length) {
        $('html, body').animate({
            scrollTop: $el.offset().top-200
        }, 500);
    }

}

export function forEach(object: any, callback: any) {
    if (Array.isArray(object)) {
        object.forEach(callback);
        return
    }

    Object.keys(object).forEach(key => {
        callback(object[key], key);
    })
}

export function setArray(entries: Array<any>, props: any = {}) {
    entries.forEach(entry => {
        forEach(props, (value: any, key: string) => {
            entry[key] = value;
        })
    })
}

export function removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y");
    str = str.replace(/đ/g,"d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g," ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    return str;
}

export function getSearchQuery(): any {
    const query: any = {};
    const queryString = location.search;
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    
    
    return query;
}

export function explode(input: any, separator: string = ','): Array<string> {
    if (!input) {
        return [];
    }

    if (typeof input !== 'string') {
        return [];
    }

    return input.split(separator);
}


export function humanFileSize(bytes: any, si=false, dp=1): string
{
    bytes = parseInt(bytes);
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export function isValidHttpUrl(string: any) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function getDiscountAmount(basePrice: number, discount: any): number {
    if (!discount) {
        return 0;
    }

    let discountAmount = 0;
    if (discount.discountType === DISCOUNT_TYPE_VND) {
        discountAmount = discount.discountValue;
    } else if (discount.discountType === DISCOUNT_TYPE_PERCENT) {
        const percent = discount.discountValue/100;
        discountAmount = percent * basePrice
    }

    return discountAmount;
}

export function getTimeRange(day = 30) {
    const start = moment().subtract(day - 1, 'days').format('YYYY-MM-DD');
    const end = moment().format('YYYY-MM-DD');
    return start + '_' + end;
}

export function isPlainObject(o: any) {
    return Object.prototype.toString.call(o) === '[object Object]';
}

export function attach(arr: any, obj: any) {
    if (!Array.isArray(arr)) {
        throw new Error('attach failed. Arr is not an array')
    }

    if (!isPlainObject(obj)) {
        throw new Error('attach failed. obj is not a plain object')
    }

    const len = arr.length;
    for (let i = 0; i < len; i++) {
        for (const k in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, k)) {
                arr[i][k] = obj[k];
            }
        }
    }

    return arr;
}

export function treeFindChecked(root: any, callback: any) {
    if (Array.isArray(root)) {
        root.forEach(node => {
            treeFindChecked(node, callback);
        })
    } else {
        if (root.checked) {
            callback(root);
        } else {
            if (Array.isArray(root.children)) {
                root.children.forEach((node: any) => {
                    treeFindChecked(node, callback);
                });
            }
        }
    }
}

export function treeValidate(root: any) {
    const idMap: any = {};
    treeIterate(root, (node: any) => {
        // if (idMap[node.id]) {
        //     console.error(`TreeValidateError: ID bị trùng ${node.id}`);
        // }

        idMap[node.id] = true;
    });
}

export function treeIterate(root: any, callback: any, level = 0) {
    if (!root) {
        throw new Error('iterateTree: root is null')
    }

    if (typeof callback !== 'function') {
        throw new Error('iterateTree: callback must be a function');
    }

    if (Array.isArray(root)) {
        root.forEach((node: any) => {
            treeIterate(node, callback);
        })
    } else {
        const result: any = callback(root, level);
        if (result === false) {            
            return;
        }

        if (Array.isArray(root.children)) {
            root.children.forEach((node: any) => {
                treeIterate(node, callback, level + 1);
            });
        }
    }

}

export function treeFindPath(root: any, child: any) {
    const typeOfChild = typeof child;
    if (typeOfChild !== "object") {
        child = treeFindNode(root, child);

        if (!child) {
            return [];
        }
    }

    const result:Array<any> = [child];
    const r2 = treeFindParents(root, child);
    result.push(...r2);
    return result;
}

export function treeFindParents(root: any, child: any) {
    const parentId = child.parent_id;

    if (parentId) {

        const result: any = [];
        treeIterate(root, (node: any) => {
            if (node.id == parentId) {
                result.push(node);
                const parentResult = treeFindParents(root, node);
                result.push(...parentResult)
            }
        });

        return result;
    } else {
        return [];
    }
}

export function treeFindNode(root: any, id: number) {
    let result = null;
    treeIterate(root, (node: any) => {
        if (node.id == id) {
            result = node;
            //return false;
        }
    });

    return result;
}

export function toLowerCase(t: string) {
    return String(t).toUpperCase();
}

export function updateTreeNode(array: any) {
    array?.forEach((o: any) => Object.assign(o, {label: o.name}));
    array?.forEach((o: any) => updateTreeNode(o.children || []))
}

export function isChangeData(objA: any, objB: any) {
    // Tạo các mảng chứa tên các property
    const aProps = Object.getOwnPropertyNames(objA);
    const bProps = Object.getOwnPropertyNames(objB);    
    // Nếu độ dài của mảng không bằng nhau,
    // thì 2 objects đó không bằnh nhau.
    if (aProps.length != bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];          
        // Nếu giá trị của cùng một property mà không bằng nhau,
        // thì 2 objects không bằng nhau.
        if (objA[propName] !== objB[propName]) {             
            return false;         
        }     
    }
    // Nếu code chạy đến đây,
    // tức là 2 objects được tính lằ bằng nhau.
    return true; 
}

